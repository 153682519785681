document.addEventListener('DOMContentLoaded', init)

function init () {
    const forms = document.querySelectorAll('.js-newsletter-form')
    console.log('forms', forms);
    for (let form of forms) {
        console.log('form', form);
        addFormEventListeners(form)
    }
}

function addFormEventListeners (form) {
    form.addEventListener('submit', onFormSubmit)
}

async function onFormSubmit (event) {
    console.log('onFormSubmit')
    event.preventDefault()

    const form = event.target

    const email = form.querySelector('input[name=email]').value
    const salutation = form.querySelector('select[name=salutation]').value
    const firstName = form.querySelector('input[name=firstName]').value
    const lastName = form.querySelector('input[name=lastName]').value
    const data = { email, salutation, firstName, lastName }

    const token = form.querySelector('input[name=CRAFT_CSRF_TOKEN]').value

    const url = '/newsletter/subscribe'

    hideFormMessages(form)

    try {
        const result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token':token
            },
            body: JSON.stringify(data)
        })

        if (result.ok) {
            showSuccessMessage(form);
        } else {
            showErrorMessage(form);
        }
    } catch (e) {
        showErrorMessage(form);
    }
}

function hideFormMessages (form) {
    const containerElement = form.closest('.w-form')
    const successElement = containerElement.querySelector('.w-form-done')
    const errorElement = containerElement.querySelector('.w-form-fail')
    successElement.style.display = 'none'
    errorElement.style.display = 'none'
}

function showSuccessMessage (form) {
    const containerElement = form.closest('.w-form')
    const successElement = containerElement.querySelector('.w-form-done')
    const errorElement = containerElement.querySelector('.w-form-fail')
    successElement.style.display = 'block'
    errorElement.style.display = 'none'
}

function showErrorMessage (form) {
    const containerElement = form.closest('.w-form')
    const successElement = containerElement.querySelector('.w-form-done')
    const errorElement = containerElement.querySelector('.w-form-fail')
    successElement.style.display = 'none'
    errorElement.style.display = 'block'
}
