document.addEventListener('DOMContentLoaded', init)

function init () {
    const emailInput = document.querySelector('#registration-email')
    const usernameInput = document.querySelector('#registration-username')
    if (!emailInput || !usernameInput) { return }

    usernameInput.value = emailInput.value

    emailInput.addEventListener('change', function () {
        usernameInput.value = emailInput.value
    })

    const form = emailInput.closest('form')
    if (!form) { return }

    form.addEventListener('submit', function() {
        console.log('copying on submit')
        usernameInput.value = emailInput.value
    })
}
